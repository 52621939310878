<template>
    <div ref="table" class="table">
        <Table :border="false" :height="height" size="small" :columns="columns1" :data="data">
            <template slot-scope="{ row }" slot="address">
                <span style="color:#3EF3FF">{{row.date}}</span>
                <!-- <span style="color:#FFCD3E">{{row.address}}</span>
                <span style="color:#FF4A22">{{row.address}}</span> -->
            </template>
        </Table>
    </div>
</template>

<script>
import {getConsumption} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            columns1: [
                {
                    title: '商户名',
                    key: 'merchantname',
                    width: 80,
                },
                {
                    title: '班级',
                    key: 'deptName',
                    width: 110,
                },
                {
                    title: '姓名',
                    key: 'nickName',
                    width: 90,
                },
                {
                    title: '消费时间',
                    key: 'address',
                    slot: 'address',    
                    align: 'center'
                }
            ],
            height: 0,
            data: []
        }
    },
    methods: {
    },
    mounted() {
        if (!this.syncId) return
        getConsumption({syncId: this.syncId}).then(res => {
            this.data = res.data
        })
        this.height = this.$refs.table.offsetHeight - 10;
    },
}
</script>

<style lang="less">
.table{
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    .ivu-table{
        background: rgba(255,255,255,0)!important;
    }
    .ivu-table th{
        background: rgba(255,255,255,0)!important;
    }
    .ivu-table-header{
        background: linear-gradient(360deg, #185FC6 0%, rgba(24,95,198,0.09) 100%)!important;
    }
    .ivu-table-wrapper{
        border: none!important;
    }
    .ivu-table-cell{
        color: #fff!important;
    }
    .ivu-table-tbody,.ivu-table td{
        background: rgba(255,255,255,0)!important;
    }
    // 整个table的下边框和右边框
	.ivu-table:before,.ivu-table:after{
        display: none!important;
	}
    .ivu-table:after {
        display: none!important;
    }
    // 整个table的上边框和左边框
	.ivu-table-wrapper {
        border:none!important;
	}
    .ivu-table th, .ivu-table td{
        border-bottom: none;
    }
    ::-webkit-scrollbar {
        width: 4px;
        
        /* background-color:none !important; */  //去掉滚动条
        background-color:rgba(24,95,198,0.1) !important;    //配置滚动条颜色,颜色可以自己定义
    }
    ::-webkit-scrollbar-thumb {
        background-color:rgba(24,95,198,0.8) !important;    //配置滚动条颜色,颜色可以自己定义
        border-radius: 10px;
        height: 20px;
        width: 10px;
    }
}
</style>